import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
import RoutePath from "@/commons/RoutePath";
import { useStore } from "vuex";
export default defineComponent({
    name: "OrganizationRegisterInvitedInvalidUrl",
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var state = reactive({});
        function goTop() {
            store.commit("setHeaderMode", "default");
            router.push(RoutePath.TOP);
        }
        return {
            state: state,
            goTop: goTop
        };
    }
});
