<template>
    <div class="organization_register_invalid_url">
        <div class="organization_register_invalid_url__body">
            <div class="mw-1000">
                <div class="organization_register_invalid_urld__body__form">
                    <form class="horizontal">
                        <div
                            class="organization_register_invalid_url__body__form-infomation"
                        >
                          URLに問題があります。<br>
                          大変お手数ですが、運営事務局(contact_manabi-dxquest@a-brain.com)までお問い合わせください。
                        </div>
                        <div class="btn_group">
                            <button
                                @click="goTop"
                                type="button"
                                class="btn-md btn-blue shadow"
                            >
                                TOPへ戻る
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register-invited/invalid-url/InvalidUrl.ts"></script>

<style lang="scss">
@import "@/presentation/views/organization/register-invited/invalid-url/InvalidUrl.scss";
</style>
